<template>
  <div class="service">
    <div class="flex-grow-1">
      <v-container>
        <v-row>
          <v-col class="d-flex flex-column" cols="12">
            <v-select
              item-text="name"
              item-value="specialization_id"
              :items="filterArr(objSpecializations)"
              v-model="selectedSpecialization"
              label="Выберите специализацию"
              outlined
              flat
              @input="dataFilter"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="objDepartmentServices">
          <v-col
            cols="12"
            v-for="(item, index) in objDepartmentServices"
            :key="index"
          >
            <v-card class="media" hover outlined>
              <div class="media-body">
                <v-row>
                  <v-col cols="12" sm="6" class="d-flex align-center">
                    <h5
                        class="media-heading"
                        itemprop="description"
                        @click="
                        openDrawerFast(
                          item.clinicDepartmentServiceId,
                          item.name,
                          objDepartment.name,
                          depId,
                          item.price,
                          objDepartment.cityPart
                        )
                      "
                    >
                      {{ item.name }}
                    </h5>
                  </v-col>

                  <v-col cols="12" sm="3" class="d-flex align-center">
                    <span class="media-price">{{ item.price }} руб. </span>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="3"
                    class="text-center text-sm-end btn-container d-flex flex-row flex-sm-column align-center justify-center"
                  >
                    <v-btn
                      outlined
                      class="btn-detail mr-2 mb-0 mb-sm-2 mr-sm-0"
                      color="primary"
                      @click="
                        openDrawerDetail(
                          item.clinicDepartmentServiceId,
                          item.name,
                          objDepartment.name
                        )
                      "
                    >
                      Подробнее
                    </v-btn>
                    <v-btn
                      outlined
                      class="btn-order"
                      color="success"
                      @click="
                        openDrawerFast(
                          item.clinicDepartmentServiceId,
                          item.name,
                          objDepartment.name,
                          depId,
                          item.price,
                          objDepartment.cityPart
                        )
                      "
                    >
                      Записаться
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <p>К сожаланию, данные отсутствуют.</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="flex-shrink-0">
      <div class="pagination text-center pb-4 pt-3"
           v-show="objPagination.actual_count >= 2">
        <v-pagination
                v-model="page"
                :length="objPagination.actual_count"
                :prev-icon="icons.mdiMenuLeft"
                :next-icon="icons.mdiMenuRight"
                @input="dataFilter"
        >
        </v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "store/names";
import { mapActions, mapGetters } from "vuex";
import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";
import { isEmpty } from "lodash";

export default {
  name: "DepartmentCardServices",
  data() {
    return {
      selectedSpecialization: null,
      loading: false,
      page: 1,
      icons: {
        mdiMenuRight,
        mdiMenuLeft,
      },
      respData: {
        id: 1,
        entity: "card",
      },
    };
  },
  computed: {
    apiAddress() {
      return names.API_ADDRESS;
    },

    filterArr() {
      return function (arr) {
        let res;
        let first = [{ specialization_id: "0", name: "Все" }];
        res = first.concat(arr);
        return res;
      };
    },

    ...mapGetters({
      depId: names.DEPARTMENT_ID_CARD,
      objDepartmentServices: names.DEPARTMENT_SERVICES,
      objSpecializations: names.DEPARTMENT_SERVICES_SPECIALIZATIONS,
      objDepartment: names.OBJ_DEPARTMENT_INFO,
      objPagination: names.OBJ_DEPARTMENT_SERVICE_PAGINATION,
    }),

    showResult() {
      return !isEmpty(this.objDepartmentServices);
    },
  },
  created() {
    this.getService(null);
  },
  mounted() {},
  methods: {
    ...mapActions({
      getDepartmentService: names.ACTION_GET_DEPARTMENT_SERVICES,
      selectResultTemplate: names.ACTION_SELECT_RESULT_TEMPLATE,
      changeEntityId: names.ACTION_TRANSMISSION_ENTITY_ID,
      selectedServiceInfo: names.ACTION_SAVE_SELECTED_SERVICE_INFO,
    }),
    async getResult() {
      await this.selectResultTemplate({
        templateName: `DepartmentCardService`,
        data: this.respData,
      });
    },

    getService(specializationId) {
      this.getDepartmentService({
        departmentId: this.depId,
        page: this.page,
        specializationId: specializationId,
      });
    },

    dataFilter() {
      if (this.selectedSpecialization == 0) {
        this.getService(null);
      }
      if (this.selectedSpecialization !== 0) {
        this.getService(this.selectedSpecialization);
      }
    },

    openDrawerFast(
      entityId,
      serviceName,
      departmentName,
      departmentId,
      servicePrice,
      cityPart
    ) {
      this.getResult();
      document.querySelector("body").style.overflow = "hidden";
      this.selectedServiceInfo({
        serviceId: entityId,
        serviceName: serviceName,
        departmentName: departmentName,
        departmentId: departmentId,
        servicePrice: servicePrice,
        cityPart: cityPart,
      });
      this.$drawer.showDrawer(true, "Fast");
    },
    openDrawerDetail(entityId, serviceName, departmentName) {
      this.getResult();
      this.changeEntityId(entityId);
      this.selectedServiceInfo({
        serviceId: entityId,
        serviceName: serviceName,
        departmentName: departmentName,
      });
      this.$drawer.showDrawer(true, "Detail");
      document.querySelector("body").style.overflow = "hidden";
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";

.media {
  text-align: left;
  margin-top: 15px;
  overflow: visible;
  padding: 30px;
  border: 1px solid #f2f2f2;
  cursor: default;

  &:hover {
    box-shadow: 0 2px 6px 5px #e3ecfc;
  }

  a {
    text-decoration: none;
  }

  &-price {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }

  &-heading {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #262626;
    padding-bottom: 5px;
    cursor: pointer;

    &:hover {
      color: #0044cc;
    }
  }

  .btn-container {
    @include smaller-phone {
      margin-top: 20px;
    }

    button {
      width: 120px;
      height: 35px;
      font-size: 13px;
      background: initial;
      text-transform: none;
      @include phone-only {
        width: 100px;
        height: 35px;
      }
    }
  }
}
</style>
